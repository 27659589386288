@import '../../scss/vars.scss';
@import '../../scss/typography.scss';


.main {
  &__container {
    display: grid;
    grid-template-columns: 1fr 669px;
    grid-template-rows: 1fr;
    gap: 20px;
  }
}

.photos {
  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    gap: 30px;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.photo {
  &__upper {
    padding: 15px 30px;
    border-radius: 8px;
    border: 2px solid #ff5050;

    opacity: 0;

    & > p {
      margin: 0;
      font-size: 25px;
      font-weight: 600;
      line-height: 1.5;
      color: #ff5050;
    }

    &_paused {
      animation: blink 1s ease infinite;
    }
  }

  &__lower {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 10px 10px 10px 50px;

    &Image {
      border-radius: 5px;
    }
  }

  &__container {
    overflow: auto;
    display: flex;
    flex: 1 1 50%;
    align-items: center;
    justify-content: center;
    max-height: 700px;
    border: 1px solid #dee4f2;
    border-radius: 5px;
    background: #ecf0fe;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: inherit;
    }
  }

  &__canvas {
    &Container {
      position: absolute;
      width: 453px;
      height: 953px;

      &_type {
        &_4 {
          position: absolute;
          width: 225px;
          height: 953px;
        }
      }
    }
  }
}

.information {

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    gap: 2px;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: scroll;

    scrollbar-color: $buttonColor transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 6px;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: $buttonColor;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.backlist__marker {
  width: 40px;
  height: 40px;
  opacity: 0;

  &_visible {
    @extend .backlist__marker;
    animation: blink 1.5s ease infinite;
  }
}

.icons {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 0 20px;
    margin-bottom: 5px;
    height: 70px;
    border: 1px solid #dee4f2;
    background: #ecf0fe;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    button {
      background-color: rgba(0, 0, 0, 0);
      margin: 0;
      padding: 0;
      border: none;
      cursor: pointer;
    }

    .pause__button {
      padding: 5px 12px;
      border-radius: 8px;
      border: 2px solid #ff5050;

      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #ff5050;
    }
  }

  &__inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
}

.popper {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 11px 18px;
    background-color: #f3f6fc;
    font-size: 14px;
    line-height: 21px;
  }

  &__item {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
  }
}

.time {
  margin: auto 0;
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.main_container {
  display: flex;
  width: 100%;
  font-family: $fontProject;
}

.component {
  height: 100%;
}

.icon {
  border-radius: 8px;
}
