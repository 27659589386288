.breadcrumbs {
  width: 100%;
  flex-direction: row;
  display: flex;
  color: #626e99;
  padding-top: 3px;
  font-size: 20px;
  align-items: center;
  cursor: pointer;

  &__item {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  &__link {
    cursor: pointer;
    text-decoration: none;
  }

  &__link:hover {
    text-decoration: none;
  }
}

.breadcrumbs_arrow_container {
  width: 8px;

  &__image {
    width: 6px;
  }
}