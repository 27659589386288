.modal_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close_container {
    padding-left: 16px;
    padding-top: 5px;
    cursor: pointer;
    background-color: transparent;
  }
}

.modal_container:focus {
  outline: none;
}
