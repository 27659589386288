.button {
  width: 100%;
  height: 48px;
  margin-top: 15px;
  color: white;
  background: #3862FA;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;

  a {
     color: white;
  }
}