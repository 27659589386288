@import '../../scss/typography.scss';

.main_container {
  display: flex;
  width: 100%;
  font-family: $fontProject;
}

.component {
  display: grid;
  grid-template-areas:
    'navigate header'
    'navigate main';
  grid-template-rows: 70px 1fr;
  grid-template-columns: 66px 1fr;
  height: 100%;
}
