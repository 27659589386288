@import '../../scss/vars.scss';

.main__wrapper {
  display: flex;
  background-color: $backgroundColor;
  height: 100vh;
}

.components__wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.no_padding {
  padding: 0 0 25px 0;
  margin: 0;
  max-width: none;

  @media (max-width: 1279px) {
    padding: 0 0 25px 0;
  }

  @media (max-width: 1023px) {
    padding: 0 0 16px 0;
  }
}