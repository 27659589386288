@import '../../scss/typography.scss';
@import '../../scss/vars.scss';

@mixin buttonDefault() {
  border: none;
  outline: none;
  background: transparent;
}

.component {
  display: flex;
  margin-right: 63px;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: $fontProject;
}
.container {
  display: flex;
  align-items: center;
  width: 552px;
  height: auto;
  flex-direction: column;
}
.logo {
  width: 552px;
  height: 117px;
}
.form {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
