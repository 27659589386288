@import '../../scss/vars.scss';
@import '../../scss/typography.scss';

* {
  box-sizing: border-box;
}

@mixin uppercase-color {
  text-transform: uppercase;
  color: $darkBlueGrayColor;
}

@mixin input-colors {
  background-color: $lightGrayColor;
  border: 1px solid $borderColor;
  outline-color: #3862fa;
  outline-width: 1px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 70px;
  height: 100vh;
  font-family: $fontProject;
}

.section {
  &Header {
    font-size: 22px;
    @include uppercase-color;
    margin: 0;
  }

  .languageHeader {
    font-size: 22px;
    @include uppercase-color;
    margin: 0;
  }

  &Subheader {
    font-size: 20px;
    @include uppercase-color;
  }
}

.section:nth-child(4),
.section:nth-child(5) {
  margin-top: 20px;
}

.parameters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 50px;
  max-width: 1195px;

  &Lang {
    margin-top: 10px;
    border-radius: 5px;
    width: 252px;
    background-color: $selectBackground;
    border: 1px solid #dee4f2;

    p {
      font-size: 18px;

      padding-right: 186px;
      padding-left: 16px;
      color: $grayDarken;
    }
  }

  &Column {
    display: flex;
    flex-direction: column;
  }
}

.parametersLang .select {
  max-width: 250px;
}

.param {
  max-width: 300px;
  padding-right: 30px;
}

.inputSection {
  display: flex;
  align-items: center;
  height: 34px;
  margin-top: 6px;

  input {
    @include input-colors;
    font-size: 18px;

    width: 100%;
    height: 100%;
    padding-left: 16px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.inputSection div:nth-child(1) {
  height: 100%;
  width: 127px;
  background: $lightGrayColor;
  border: 1px solid $borderColor;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;

  input,
  div:nth-child(1) {
    border: none;
  }

  input {
    width: 100%;
  }
}

input,
.type {
  @include p3;

  color: $grayDarken;
}

.type {
  @include input-colors;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 0 20px;
  height: 100%;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 18px;
}

.variants {
  display: none;
}

.button {
  font-size: 18px;
  width: 100%;
  height: 44px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  color: $whiteColor;
  background-color: $buttonColor;
  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}
