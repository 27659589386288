.select {
  cursor: pointer;
  z-index: 5;
  position: relative;
  width: 100%;
  height: 100%;

  &Trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 6px 6px 6px 16px;
    background: #f3f6fc;
    border-radius: 5px;
    white-space: nowrap;
    width: 100%;
    height: 100%;

    &Value {
      color: #626e99 !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 250px;
    max-height: 554px;
    margin-top: 4px;
    padding: 14px 12px 2px 12px;
    background: #f3f6fc;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12), 0 12px 20px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: red;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  &Item {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 24px;

    &Value {
      white-space: nowrap;
      color: #364063;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      padding: 5px;
      line-height: 20px;

      &_Active {
        background-color: #3862fa;
        color: white !important;
        border-radius: 8px;
      }
    }
  }
}
