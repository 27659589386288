.component {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 34px 36px 36px;
  width: 70%;
  height: 20%;
  min-width: 300px;
  max-width: 500px;
  box-sizing: border-box;
  background-color: #fff;
}

.error {
  color: red;
  text-align: center;
  font-size: 20px;
}