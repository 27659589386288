@import '../../../../scss/vars.scss';
@import '../../../../scss/typography.scss';

.component {
  display: flex;
  justify-content: center;
  overflow: hidden;
  border: none;
  border-radius: 8px;
  width: 412px;
  margin-top: 15px;
  height: 48px;
  box-shadow: 0 4px 10px rgba(72, 69, 183, 0.149);
  @include mediumP2($whiteColor);
  background-position: left bottom;
  background-size: 200% 100%;
  background-color: $buttonColor;
  transition: background-color 0.4s;
  cursor: pointer;
  &:hover:enabled {
    background-color: $lightBlackberryColor;
    transition: background-color 0.4s;
  }
  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
}
.load {
  background: linear-gradient(90deg, $buttonColor 20%, $lightBlackberryColor 30%, $buttonColor 10%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
.icon {
  display: flex;
  align-self: center;
}
