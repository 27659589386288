@import '../../scss/vars.scss';
@import '../../scss/typography.scss';

@mixin text-size {
  font-size: 22px;
  margin: 0;
  line-height: 26.63px;
  padding-top: 7px;
  color: $darkBlueGrayColor;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);

  & > p {
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
  }
}

.main {
  &_container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    gap: 50px;
    padding: 30px;
    font-family: $fontProject;
    height: calc(100vh - 140px);
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: 1px solid #dee4f2;
  }
}

.pagination {
  &_top {
    margin-bottom: 20px;
  }

  &_bottom {
    width: 100%;
  }
}

.load {
  &_text {
    @include text-size;
    text-align: center;
    min-width: 91vw;
    overflow: hidden !important;
  }
}

.folder {
  @include flex-column;

  text-decoration: none;
  align-items: center;

  p {
    @include text-size;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: repeat(1, auto);
  overflow-y: scroll;
  height: 75vh;
  gap: 10px;
  margin-bottom: 35px !important;

  scrollbar-color: $buttonColor transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 6px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: $buttonColor;
  }
}

.image {
  @include flex-column;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  width: 250px;
  height: fit-content;
  background-color: $lightGrayColor;
  padding: 5px;
  margin: 10px;
  border: 1px solid $imagesCardBorder;
  border-radius: 5px;
  box-shadow: $primaryBoxShadow;

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    overflow-y: scroll;
    gap: 0;
  }

  &__text {
    @include text-size;
    font-size: 18px !important;
  }

  img {
    margin-bottom: 8px;
    border-radius: 5px;
    border: 1px solid $imagesCardBorder;
    width: 100%;
    height: 250px;
  }
}


// модальное окно с открытым изображением
.opened {
  display: flex;
  flex-direction: column;
  width: 1000px;
  max-height: calc(min(99vh, 925px));
  padding: 15px;
  background-color: $lightGrayColor;
  border: 1px solid #dee4f2;
  border-radius: 5px;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
  }

  &Table {
    @include p4;

    border-collapse: collapse;
    background-color: $lightGrayColor;
    color: $darkBlueGrayColor;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 14px;
  }

  &Close {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &Image {
    position: relative;
    align-self: center;
    border: 1px solid $imagesCardBorder;
    max-width: 920px;
    width: 100%;
    max-height: 900px;
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      right: 5px;
      background-color: #626e99;
      border-radius: 5px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #3862fa;
      border-radius: 8px;
    }

    &_img {
      width: 100%;
      height: 100%;
      top: 0;
    }
  }
}

td {
  padding: 12px 0;
  border: 1px solid $modalTableBorder;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.download_button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.download_btn {
  padding: 0 15px;
  border: 1px solid $buttonColor;
  border-radius: 8px;

  font-size: 16px;
  line-height: 28px;
  color: $darkBlueGrayColor;

  background-color: transparent;
  outline: none;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    @include p1;

    color: $darkBlueGrayColor;
    padding-left: 20px;
  }
}

.canvas {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 14000;
}

.item__scale {
  margin: 0 0 8px 0;
  height: 20px;
  width: 100%;
  border-radius: 5px;
}

.defects__info {
  & > p {
    margin: 0 0 8px 0;

    font-size: 16px;
    line-height: 1;
    color: $darkBlueGrayColor;
  }
}

.switchBack__container {
  display: flex;
  align-items: center;
  margin: 20px 37px 20px auto;
  width: fit-content;
  gap: 5px;
  & > p {
    margin: 0;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    white-space: nowrap;
  }
}

@media all and (max-width: 1800px) {
  .list {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media all and (max-width: 1500px) {
  .list {
    grid-template-columns: repeat(4, 1fr);
  }

  .main_container {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}

@media all and (max-width: 1250px) {
  .list {
    grid-template-columns: repeat(3, 1fr);
  }

  .main_container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

@media all and (max-width: 550px) {
  .main_container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
