.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-content: center;
  color: #626E99;
  background-color: #ECF0FE;
  box-shadow: 0px 4px 15px rgba(57, 67, 89, 0.15);
  font-size: 20px;
  border: 1px solid #626E99;
  border-radius: 10px;
  z-index: 15000;
}

.title {
  line-height: 24px;
  color: #626E99;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.subtitle {
  margin-bottom: 15px;
  color: #626E99;
  text-transform: uppercase;
}

.list {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 14px;
  padding: 7px 12px;
  background: #F3F6FC;
  border: 1px solid #E8EDF7;
  border-radius: 6px;
}

.items {
  &_container {
    padding: 20px 0;
    color: #364063;
  }
}

.report {
  &_container {
    margin-top: 15px;
    padding-bottom: 37px;
  }
}

.button {
  width: 100%;
  height: 48px;
  color: white;
  background: #3862FA;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-transform: uppercase;
}

.input {
  &_search {
    padding: 7px 11px;
    margin-bottom: 32px;
    border: 2px solid #3862FA;
    border-radius: 6px;
  }
}