@import '../../scss/typography.scss';
@import '../../scss/vars.scss';

.navigate {
  &Container {
    grid-area: navigate;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 7px 5px;
    background: $lightGrayColor;
    border: 1px solid #dee4f2;
  }

  &List {
    display: flex;
    width: 51px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 45px;
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    cursor: pointer;
    min-width: 51px !important;
    max-width: 51px !important;

    a {
      display: flex;
      justify-content: center;
      width: 51px;
    }
  }
}

.button {
  border: none;
  background: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
}
