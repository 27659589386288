.component {
  border: none;
  padding: 0 12px;
  background-color: transparent;
  opacity: 1;
  transition: opacity 0.4s;
  cursor: pointer;
  &:hover {
    border: none;
    opacity: 0.5;
    transition: opacity 0.4s;
    cursor: pointer;
  }
}
