.switcher__container {
  display: flex;
  align-items: center;
  padding: 3px 5px; 
  border-radius: 30px;
  color: #626e99;
  cursor: pointer;
}

.toggle__point {
  border-radius: 1000%;
}