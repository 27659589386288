@import '../../../../scss/typography.scss';

.item {
  display: flex;
  align-items: center;
  padding: 20px;

  p {
    margin: 0;
    color: #626e99;
    font-size: 20px;
    line-height: 27px;
    font-family: $fontProject;
  }

  &__scale {
    margin-right: 9px;
    height: 190px;
    width: 25px;
    border: 1px solid #dee4f2;
    border-radius: 5px;
  }

  &__image {
    width: 190px;
    height: 190px;
    border: 1px solid #dee4f2;
    margin-right: 16px;
    border-radius: 5px;
  }
}

.generalInformation {
  &__container {
    margin-right: 20px;
  }
}
