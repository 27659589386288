@import '../../scss/vars.scss';
@import '../../scss/typography.scss';

* {
  box-sizing: border-box;
}

h3,
h2 {
  margin: 0;
  color: $darkBlueGrayColor;
}

p {
  @include p3;
  margin: 0;
  color: $darkBlueGrayColor;
}

.mainContainer {
  width: 100%;
  height: calc(100vh - 70px - 50px);
}

.scrolledContainer {
  width: 100%;
  margin: 25px 0;
  padding: 0 25px;
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-color: red transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: red;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.statContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  height: 100%; /*100vh;*/
  margin: 25px;
  gap: 35px;
  font-family: $fontProject;
}

.wrapper {
  width: 100%;
  display: flex;
  gap: 25px;
  justify-content: center;
  flex-wrap: wrap;
  color: $darkBlueGrayColor;
}

.flexWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
}

.time,
.noDataText {
  @include h2;
  font-weight: 400;
  text-transform: uppercase;
  color: $darkBlueGrayColor;
}

.types,
.period {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 34px;
  background-color: $selectBackground;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  color: #364063;
}

.period {
  padding: 6px 6px 6px 16px;
  color: $darkBlueGrayColor;
}

.commonStatContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  width: 100%;
  text-align: center;
}

.sectorsStatContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 15px;
  justify-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1250px;
}

.sectorStat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: $darkBlueGrayColor;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.title {
  margin-bottom: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: $darkBlueGrayColor;
}

.noDataTextWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.responsiveContainer {
  display: flex;
  justify-content: center;
  max-width: 1200px;
}

[class='recharts-wrapper'] {
 max-width: 1200px !important;
}
