@import '../../scss/typography.scss';

.found_container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: calc(100vh - 210px);
  font-family: $fontProject;
}

@media (max-width: 1024px) {
  .found_container {
    height: calc(100vh - 190px);
  }
}
