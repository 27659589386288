@import '../../../scss/vars.scss';
@import '../../../scss/typography.scss';

.DatePicker {
    padding: 0;
}

.DatePicker input {
    padding: 5px;
    border: 1px solid $borderColor;
    border-radius: 6px;
    font-size: 14px !important;
    text-align: left;
    color: rgba(0, 0, 0, 0.85) !important;
}

.DatePicker input:hover {
    border-color: #40a9ff;
}

.DatePicker input:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.cancelBtn {
    color: #364063 !important;
} 

.btnsBlock {
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
}

.addBtn,
.saveBtn {
    border-radius: 6px;
    background-color: #3862fa;
}
.addBtn[disabled],
.saveBtn[disabled] {
    background-color: #3862fa;
    cursor: default;
    opacity: 0.5;
}

.addBtn[disabled]:hover,
.saveBtn[disabled]:hover {
    background-color: #3862fa;
    cursor: default;
    opacity: 0.5;
}

.tableWrapper table {
    width: 100%;
    max-width: 900px;
}

.table div[class*="ant-table"]::-webkit-scrollbar {
    position: relative;
    width: 3px;
    left: 5px;
}

.table div[class*="ant-table"]::-webkit-scrollbar-thumb {
    background-color: red;
    border-radius: 3px;
}

// .table div[class*="ant-table"]::-webkit-scrollbar-track {
//     background-color: transparent;
// }

.table div[class*='ant-table-container']::after {
    width: 3px;
}

.table th[class*='ant-table-cell-scrollbar'] {
    padding-left: 5px;
    padding-right: 5px;
}

.table table > thead > tr > th {
    background: $lightGrayColor;
    border: 1px solid $borderColor;
    border-left: none;
} 

.table table > thead > tr > th::before {
    display: none;
} 

.table table > thead > tr:first-child th:first-child {
    border-top-left-radius: 6px;
    border-left: 1px solid $borderColor;
}
.table table > thead > tr:first-child th:last-child {
    border-top-right-radius: 6px;
}

.row > td {
    border: none;
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor !important;
}

.row > td:first-child {
    border-left: 1px solid $borderColor;
}

.row:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

.row:last-child td:last-child {
    border-bottom-right-radius: 6px;
}

.select div {
    border-radius: 6px !important;
}

[class*='rc-virtual-list-scrollbar-vertical'] {
    width: 3px !important;
}

[class*='rc-virtual-list-scrollbar-thumb'] {
    background: red !important;
}