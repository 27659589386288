@import '../../../../scss/typography.scss';
@import '../../../../scss/vars.scss';

@mixin buttonDefault() {
  border: none;
  outline: none;
  background: transparent;
}

.inputWrap {
  width: 412px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 2px 0;
  border: 1px solid $borderColor;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: $inputBackgroundColor;
  &:focus {
    border: 1px solid $greyRavenColor;
  }
  &Success {
    border: 1px solid $successColor;
  }
  &Warning {
    border: 1px solid $warningColor;
  }
  &Error {
    border: 1px solid $errorColor;
  }
}
.input {
  display: block;
  flex-grow: 1;
  border-radius: 6px;
  padding: 12px;
  box-sizing: border-box;
  @include buttonDefault();
  @include regularP2($inputTextColor);
}
.icon {
  padding: 0 12px 0 0;
  background-color: transparent;
}

.textError {
  display: block;
  margin-bottom: 2px;
  height: 16px;
  @include mediumP4($inputTextColor);
  &Success {
    color: $successColor;
  }
  &Warning {
    color: $warningColor;
  }
}
